.exposureAndSymptomTitle {
  font-size: 14px;
  font-weight: 800;
  border-style: none none solid none;
  border-width: 1px;
}

.exposureAndSymptomTitle p {
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 14px;
}
.symptomsTitle {
  font-weight: 800;
  border-style: none none solid none;
  border-width: 1px;
}

.infectiousSlider {
  grid-area: 3/1/3/3;
  margin-bottom: 30px;
}

.floatingRight {
  float: right;
}

.floatingLeft {
  float: left;
}
.riskAndSymptoms {
  margin-right: 20px;
}
