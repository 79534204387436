.result {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1.4px;
    margin-bottom: 0;
    padding-left: 10px;
    color:white
}

.posNegContainer {
    padding-bottom: 10px;
    width: 100%;
}
