@import url('https://fonts.googleapis.com/css?family=Rasa:400,500|Lato:400,600&display=swap');

@import '../src/partials/variables.scss';

:root {
    font-size: 16px;
}

html {
    height: 100%;
}

body {
    font-family: $sansFont;
    font-weight: 400;
    line-height: 1.45;

    position: relative;

    min-height: 100%;
    margin: 0;

    color: $darkGray;
    background-color: #dce3e3;
}

#root {
    min-height: 100%;
}

p {
    font-family: $serifFont;
    font-size: 1.25rem;
    line-height: 1.15;

    margin-top: 1rem;
    margin-bottom: 1.25rem;
}

.help-text {
    font-family: $sansFont;
    font-size: inherit;
}

.invalid-text {
    font-family: $sansFont;
    font-size: 0.8rem;
    font-weight: bold;

    color: $red;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $sansFont;
    font-weight: 400;
    line-height: 1.15;

    margin: 1.5rem 0 1rem;

    .separator-bottom + section & {
        margin-top: 0.5rem;
    }
}

.h1 {
    font-size: 2.441em;

    margin-top: 0;
}

.h2 {
    font-size: 1.953em;
}

.h3 {
    font-size: 1.563em;
}

.h4 {
    font-size: 1.25em;
}

.h5 {
    font-weight: 600;

    margin-top: 1.25em;
}

.small {
    font-size: 0.8em;
}

.flex {
    display: flex;
    align-items: baseline;
}

.flex-center {
    display: flex;
    align-items: center;
}

.jumbo {
    font-family: $serifFont;
    font-size: 3.2em;
    font-weight: 500;
    line-height: 1;

    margin-top: 0;
}

.tag {
    font-size: 0.8em;

    display: inline-block;

    min-height: 1.5em;
    margin-right: 1em;
    padding: 0.5em 1em;

    text-transform: uppercase;

    color: $medPurple;
    border-radius: 1.5em;
    background-color: $lightPurple;
}

.main {

    margin: 0 auto;

    &.content {
        padding-bottom: 1rem;
    }
}

.button {
    font-family: $sansFont;
    font-size: inherit;
    font-weight: 600;

    display: inline-block;

    padding: 10px 20px;

    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;

    color: white;
    border: 2px solid $darkPurple;
    border-radius: 0.2em;
    background-color: $darkPurple;
}

button.button {
    height: 44px;

    &:hover:not([disabled]) {
        opacity: 0.8;
    }

    &[disabled] {
        cursor: default;

        opacity: 0.6;
    }
}

.input {
    font-family: $sansFont;
    font-size: 1rem;
    line-height: 1.75rem;

    display: inline-block;

    padding: 0.5rem 1rem;

    text-decoration: none;

    border: 2px solid $darkPurple;
    border-radius: 0.2rem;

    .invalid & {
        border-color: $red;
    }

    &.input-inline {
        max-width: 40px;
        margin-right: 3px;
        padding: 0 0.5rem;
    }

    &[readonly] {
        cursor: not-allowed;

        border-color: $lightGray;
    }
}

.hide {
    display: none;
}

.separator-bottom {
    padding-bottom: 1.5rem;

    border-bottom: 1px solid #efefef;
}

.separator-top {
    padding-top: 2rem;

    border-top: 1px solid #efefef;

    .separator-bottom + & {
        border-top: 0;
    }
}

.spacer-bottom {
    padding-bottom: 1.5rem;
}

.spacer-top {
    padding-top: 2rem;
}

.indent-content {
    margin-left: $indent;
}

/*
@media (min-width: 320px) {
}

@media (min-width: 480px) {
    .jumbo {
        font-size: 4.8em;
    }

    .h1 {
        font-size: 3.052em;
    }

    .h2 {
        font-size: 2.441em;
    }

    .h3 {
        font-size: 1.953em;
    }

    .h4 {
        font-size: 1.563em;
    }

    .h5 {
        font-size: 1.25em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 2.5rem 0 1rem;

        .separator-bottom + section & {
            margin-top: 1.5rem;
        }
    }
}

@media (min-width: 768px) {
    .jumbo {
        font-size: 5.875em;
    }
}

@media print {
    :root {
        font-size: 14px;
    }

    .hide-print {
        display: none;
    }

    strong {
        // Bold fonts look messed up in pdf exports in safari / firefox
        font-weight: normal;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 14px 0 14px;
    }

    body {
        // Enable display of background-color when printing
        color-adjust: exact;
    }

    .jumbo {
        font-size: 60px;
    }

    .main {
        max-width: 700px;
    }

    a {
        text-decoration: none;
    }

    .small {
        font-size: 9px;
    }

    .input.input-inline {
        font-family: inherit;
        font-size: inherit;

        max-width: 26px;
        margin-right: 0;
        padding: 0;

        text-align: right;

        color: $darkGray;
        border: none;
    }

    .separator-top {
        padding-top: 7px;

        border-top: 0;
    }

    .separator-bottom {
        padding-bottom: 7px;

        border-bottom: 0;
    }
}
*/
