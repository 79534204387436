.section-left {
    width: 40%;
    float: left;
}
.section-right {
    width: 60%;
    margin-left: 30px;
}

.infoLine {
    font-size: 14px;
    color: #20494c;
    font-weight: normal;
    margin-bottom: 0;
    border-style: none none solid none;
}

.sampleTakenDoyOfTest {
    font-size: 14px;
    margin-bottom: 0px;
    color: #262420;
}

.stepQuestion {
    font-size: 24px;
    color: #262420;
    margin-bottom: 0px;
    margin-top: 5px;
    font-weight: 700;
}




.floatingRight {
    float: right;
}

.floatingLeft {
    float: left;
}
