.numberSize {
  font-size: 36px;
  font-weight: 900;
  color: white;
  margin: 0 0 0 10px;
}

.explanationText {
  font-size: 12px;
  margin: 0 0 0 10px;
  color: white;
}

.numTruePos {
  border-style: none none solid none;
  border-width: 1px;
  border-color: white;
  background-color: #ab5936;
}

.numFalseNeg {
  border-style: none none solid none;
  border-width: 1px;
  border-color: white;
  background-color: #ff6c31;
}

.numFalsePos {
  border-style: none none solid none;
  border-width: 1px;
  border-color: white;
  background-color: #1a97a4;
}

.numTrueNeg {
  background-color: #2d7175;
}

.underline {
  border-style: none none solid none;
  border-width: 1px;
  border-color: white;
}
