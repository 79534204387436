.stateCounty {
    margin-bottom: 20px;
}

.multiplierPercetageBox {
    display: grid;
    grid-template-columns: [leftSection] 384px [rightSection] auto;
    grid-template-rows: auto auto auto;
}

.multiplierPercetageBoxBasic {
    grid-area: 3/1/3/3;
    display: grid;
    grid-template-columns: [leftSection] auto [rightSection] auto;
    grid-template-rows: auto auto auto;
}



.floatingRight {
    float: right;
}
.stepOneline {
    float: left;
    width: 1px;
    height: 100%;
    border-style: none solid none none;
    grid-area: 1/1/4/1;
    border-width: 1px;
    margin-left: -34px;
    color: #81A2A4;
    margin-top: 36px;        
    color: #81A2A4;
}
.percentageBoxBasic {
    height: 45px;   
    margin-left: 20px;
}
.percentageBox {
    height: 45px;
    margin-top: 20px;
    margin-left: 20px;
}


.estimateMultiplier {
    font-size: 14px;
    margin: 0;
    border-style: none none solid none;
    border-width: 1px;
}

/*

  width: 700px;
    height: 175px;


    grid-area: 1/1/1;

     grid-area: 1/2/1;
*/
