.innerBoxBox {
  width: 505px;
  background-color: #707070;
}

.informationDiv {
  background-color: #ebeae8;
  border-radius: 0px 0px 6px 6px;
  height: auto;
}

.belowTabs {
  display: grid;
  grid-template-columns: 483px;
  grid-template-rows: 40px auto;
  width: auto;
  height: auto;
}

.chart {
  grid-area: 2/1/2/1;
}

.chartLabels {
  grid-area: 2/1/2/1;
}
.chartTitle {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
}

.chartTitle p {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.4px;
  margin: 25px 0 0 10px;
}

.posNegSelection {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  margin-top: 30px;
}

.mobileFloatWrapper {
  max-width: 638px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -4px;
}

.floaterTitle {
  height: 44px;
  background-color: #3f3e3b;
  border-radius: 6px 6px 0px 0px;
}

.floaterTitle p {
  font-size: 18px;
  color: #ffffff;
  margin: 10px 0 0 22px;
  font-weight: bold;
  letter-spacing: 2.5px;
}
.percentNumber {
  font-size: 36px;
}
.percentSign {
  font-size: 38px;
}

.divWrapper {
  display: grid;
  z-index: 1000;
}

.mobileTestResults {
  font-size: 42px;
  font-weight: 900;
  margin-top: 0;
  padding-left: 20px;
}
.mobileTestResultsTitle {
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
.testNumberWrapper {
  border-right: solid;
  border-width: 1px;
  border-color: lightgray;
  margin-left: 20px;
  padding-right: 20px;
  margin-top: 26px;
}
.prediction {
  font-size: 14px;
}

@media (max-width: 1280px) {
  .floaterTitle {
    border-radius: 0px;
  }
  .informationDiv {
    border-radius: 0px;
  }
  .posNegSelection {
    padding-left: 10px;
  }
  .divWrapper {
  }
}
