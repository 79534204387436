.container {
  display: grid;
  max-width: 1365px;

  background-color: #dce3e3;

  font-size: 14px;
  margin-top: 150px;
}

.covidStepperWrapper {
  margin-right: 20px;
  margin-top: 130px;
}

.copywriteWrapper {
  display: flex;
}

.floater {
  display: inline;
  margin-top: 30px;
  min-width: 494px;
  position: fixed;
  z-index: 1000;
  top: 105px;
}

.stepTwo {
  padding-bottom: 30px;
}

.stepThree {
  padding-bottom: 30px;
}

.titles {
  margin-right: 20px;
  margin-left: 30px;
}

.understandingLine {
  font-size: 18px;
  color: #20494c;
  font-weight: 900;
  letter-spacing: 2.5px;
  margin-bottom: 0;
}

.testingLine {
  font-size: 36px;
  color: #262420;
  font-weight: 900;
}

.infoLine {
  font-size: 14px;
  color: #20494c;
  font-weight: normal;
  margin-bottom: 0;
}

.footerWrapper {
  background-color: #262420;
  margin-top: 50px;
  height: fit-content;
}

@media (max-width: 1280px) {
  .floater {
    width: 100%;
    margin-top: 0;
    top: 60px;
    height: 50px;
  }
  .covidStepperWrapper {
    width: 30px;
  }
}
