@import '../../partials/variables.scss';

.step-active .h3 {
    font-weight: 600;

    color: $darkPurple;
}

.step-help {
    margin-top: 0.5rem;

    .help-text {
        font-size: 0.8em;
    }
}
section .tag {
    display: none;
}

.section-progress {
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.big-num {
    font-family: $serifFont;
    font-weight: 400;

    min-width: $indent;
}

.question .button-link span {
    color: $turquoise;
    border-bottom: 2px solid $turquoise;
}

.tag-text {
    display: inline-block;

    p {
        margin: 0;

        & + p {
            margin-top: 0.5rem;
        }
    }
}

.progress-button {
    text-transform: unset;

    border-radius: 22px;

    &:focus {
        outline: none;
        box-shadow: 0 0 3px 2px $brightBlue;
    }
}

#prior-input {
    flex: 1;

    width: 0;
    margin-right: 5px;
}

label[for='prior-input'] {
    flex: 5;
}

.prior-input-container {
    align-items: end;
}

@media (min-width: 480px) {
    section .tag {
        display: block;
    }

    #prior-input {
        flex: 1;

        max-width: 40px;
    }
}

@media (min-width: 600px) {
    .prior-input-container {
        align-items: center;
    }
}

@media (min-width: 768px) {
    .section-progress {
        margin-top: 3.125rem;
    }
}

@media print {
    .section-progress {
        margin-top: 0;
        padding-bottom: 0;

        // Hide contents but leave the element to retain spacing with footer
        * {
            display: none;
        }
    }

    div:not(.cols):not(.col) {
        break-inside: avoid;
    }

    @page {
        margin: 0;
    }
}
