$darkGray: #3c3e4e;
$medGray: #4c515a;
$lightGray: #b6c1cd;

$darkPurple: #20494c;
$medPurple: #1b97a4;
$lightPurple: #98b4b6;

$purpleGray: #d5d0dc;

$neutralPurple: #beb6cd;

$turquoise: #57c2be;

$brightBlue: #3c98ff;
$medBlue: #5399e8;

$red: #ff4259;
$yellow: #fcd74f;

$sansFont: 'Open Sans', 'Courier', 'Lato', sans-serif;
$serifFont: 'Open Sans', 'Courier', 'Rasa', serif;

$indent: 26px;

$outline: $brightBlue auto 5px;

$infectiousOrange: #ea5a2b;
$lightInfectiousOrange: #e0a689;
$standardBlack: #000000;


$floatboxInnerBackground:#46443D;